import AdminPanel from "../../../vendor/arbory/arbory/resources/assets/js/admin";
import { CONFIG_EDITOR } from "./admin/RichText";


AdminPanel._registry._definitions.forEach( function( item ) {
    if ( item._handler.name === 'RichText' ) {
        item.config  = CONFIG_EDITOR;
    }
} );

AdminPanel.initialize();
