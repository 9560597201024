export const CONFIG_EDITOR = {
        language: 'lv',
        entities_latin: false,
        forcePasteAsPlainText: true,
        height: '400px',
        allowedContent: false,
        format_tags: 'p;h2;h3;h4;h5',
        fontSize_sizes: '12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;30/30px;32/32px;34/34px;36/36px;38/38px;46/46px;',
        colorButton_enableAutomatic: true,
        extraPlugins: 'mediaembed,font,showblocks',
        coreStyles_bold: {element: 'b', overrides: 'strong'},
        coreStyles_italic: {element: 'i', overrides: 'em'},
        toolbar: [
            {
                name: 'document',
                groups: ['mode', 'document', 'doctools']
            },
            {
                name: 'clipboard',
                groups: ['clipboard', 'undo'],
                items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
            },
            '/',
            {name: 'styles', items: ['Format', 'Styles', 'FontSize']},
            {
                name: 'basicstyles',
                groups: ['basicstyles', 'cleanup'],
                items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat']
            },
            {
                name: 'paragraph',
                groups: ['list', 'indent', 'blocks'],
                items: ['NumberedList', 'BulletedList','Blockquote',]
            },
            {name: 'links', items: ['Link', 'Unlink', 'Anchor']},
            {
                name: 'insert',
                items: ['Image', 'MediaEmbed', 'Table', 'HorizontalRule']
            },
            '/',
            {name: 'tools', items: ['Source', 'Maximize', 'ShowBlocks']},
        ],
        stylesSet: [
            {name: 'Small', element: 'small'},
            {name: 'uppercase', element: 'span', attributes: {'class': 'uppercase'}}
        ]

    }
;

export default class RichText {
    constructor(element, config = {}) {
        if (element.id in CKEDITOR.instances) {
            CKEDITOR.instances[element.id].destroy();
        }

        this.element = element;
        this.config = config;

        this.initialize();
    }

    initialize() {
        let textarea = this.getTextarea();
        let config = Object.assign(this.getDefaultConfig(), {
            width: '100%',
            height: textarea.outerHeight()
        });
        if (!textarea.attr('id')) {
            textarea.attr('id', 'richtext_' + String((new Date()).getTime()).replace(/\D/gi, ''));
        }

        if (textarea.data('attachment-upload-url')) {
            config.filebrowserUploadUrl = textarea.data('attachment-upload-url');
        }

        if (textarea.data('external-stylesheet')) {
            config.contentsCss = textarea.data('external-stylesheet');
        }

        for (let [key, value] of Object.entries(this.getCustomConfig())) {
            config[key] = value;
        }

        CKEDITOR.replace(this.element, config);

        this.registerEventHandlers(textarea);
    }

    registerEventHandlers() {
        let textarea = this.getTextarea();
        let form = textarea.closest("form");

        form.on('beforevalidation', () => {
            for (let instance in CKEDITOR.instances) {
                if (CKEDITOR.instances.hasOwnProperty(instance)) {
                    CKEDITOR.instances[instance].updateElement();
                }
            }
        });

        textarea.on('richtextresume', () => {
            if (!textarea.data('richtext-suspended')) {
                return;
            }

            textarea.show();
            textarea.data('richtext-suspended', false);

            this.initialize();
        });

        textarea.on('richtextsuspend', () => {
            if (textarea.data('richtext-suspended')) {
                return;
            }

            CKEDITOR.instances[textarea.attr('id')].destroy();

            textarea.hide();
            textarea.data('richtext-suspended', true);
        });

        textarea.on('focusprepare', () => {
            if (textarea.data('richtext-suspended')) {
                return;
            }

            CKEDITOR.instances[textarea.attr('id')].focus();
        });
    }

    getCustomConfig() {
        return this.config;
    }

    getTextarea() {
        return jQuery(this.element);
    }

    getDefaultConfig() {
        return CONFIG_EDITOR;
    }

    getToken() {
        let token = document.head.querySelector('meta[name="csrf-token"]');

        return token ? token.content : null;
    }
}