"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popup = void 0;
var $ = require("jquery");
var POPUP_SHOW_TIMEOUT = 300;
var POPUP_DESTROY_TIMEOUT = 300;
var Popup = /** @class */ (function () {
    function Popup() {
        var _this = this;
        this.show = function () {
            //TODO: if ajax, resolve after response received
            return new Promise(function (resolve, reject) {
                _this.destroyOthers();
                _this.$container.addClass('popup--visible');
                setTimeout(function () {
                    return resolve();
                }, POPUP_SHOW_TIMEOUT);
            });
        };
        this.hide = function () {
            _this.$container.removeClass('popup--visible');
            setTimeout(function () {
                _this.destroy();
            }, POPUP_DESTROY_TIMEOUT);
        };
        this.destroy = function () {
            _this.$container.remove();
        };
        this.destroyOthers = function () {
            Popup.instances.filter(function (popup) {
                return popup !== _this;
            }).forEach(function (popup) {
                popup.destroy();
            });
        };
        Popup.instances.push(this);
        this.$container = $('.js-popup');
    }
    Popup.instances = [];
    return Popup;
}());
exports.Popup = Popup;
