"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("slick-carousel");
var $ = require("jquery");
var Store = require("../helpers/store");
var StylesLoaded = function () {
    return new Promise(function (resolve, reject) {
        var interval = setInterval(function () {
            if (Store.default.getCookie('stylesLoaded')) {
                clearInterval(interval);
                return resolve();
            }
        }, 10);
    });
};
function initSlider() {
    var $sliderPrimary = $('.js-slider');
    $sliderPrimary.slick({
        infinite: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        dots: true,
        prevArrow: '<div class="prev"><svg><use xlink:href="#icon-arrow-left"></use></svg></div>',
        nextArrow: '<div class="next"><svg><use xlink:href="#icon-arrow-right"></use></svg></div>'
    });
}
var sliderReady = function () {
    return new Promise(function (resolve, reject) {
        initSlider();
        return resolve();
    });
};
function tabs() {
    var $tab = $('.js-tabs');
    $tab.on('click', function (e) {
        e.preventDefault();
        var $target = $($(this).attr('href')), $btn = $(this);
        if (!$btn.hasClass('active')) {
            $btn.addClass('active').siblings().removeClass('active');
            $target.addClass('active').siblings().removeClass('active');
        }
    });
}
function popup() {
    $('.js-popup').on('click', function (e) {
        e.preventDefault();
        var $this = $(this), $targetId = $this.data('target'), $popupBg = $('.pop-bg');
        $('body').toggleClass('locked');
        $popupBg.fadeToggle().toggleClass('open');
        setTimeout(function () {
            if ($popupBg.hasClass('open')) {
                var clone = $($targetId).clone(true);
                (clone).appendTo($popupBg).fadeIn();
            }
            else {
                $popupBg.children().remove().fadeOut();
            }
        }, 200);
    });
}
var onStart = function () {
    StylesLoaded()
        .then(sliderReady);
    popup();
    tabs();
};
exports.default = { onStart: onStart };
