"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("slick-carousel");
var $ = require("jquery");
var Store = require("../helpers/store");
var StylesLoaded = function () {
    return new Promise(function (resolve, reject) {
        var interval = setInterval(function () {
            if (Store.default.getCookie('stylesLoaded')) {
                clearInterval(interval);
                return resolve();
            }
        }, 10);
    });
};
function initSlider() {
    var $sliderPrimary = $('.js-img-slider');
    $sliderPrimary.slick({
        infinite: false,
        swipe: true,
        dots: true,
        mobileFirst: true,
        prevArrow: '<div class="prev"><svg class="icon"><use xlink:href="#icon-back"></use></svg></div>',
        nextArrow: '<div class="next"><svg class="icon"><use xlink:href="#icon-next"></use></svg></div>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    variableWidth: true,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}
var sliderReady = function () {
    return new Promise(function (resolve, reject) {
        initSlider();
        return resolve();
    });
};
function getPosts(btn) {
    var $page = btn.data('page');
    var url = window.location.href;
    $.ajax({
        url: url + '?page=' + $page,
    }).done(function (data) {
        $('.projects').append(data);
        if (btn.data('last') === $page) {
            btn.data('page', 2);
            btn.fadeOut();
        }
        else {
            $page += 1;
            btn.data('page', $page);
        }
    }).fail(function (data) {
        console.log('fail');
    });
}
$('.js-ajax-more').on('click', function (e) {
    e.preventDefault();
    getPosts($(this));
});
var onStart = function () {
    StylesLoaded()
        .then(sliderReady);
};
exports.default = { onStart: onStart };
