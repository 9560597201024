"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
function getPosts(btn) {
    var $year = btn.attr('href');
    var url = window.location.href;
    $.ajax({
        url: url + '?year=' + $year,
    }).done(function (data) {
        $('.history__table__body').html(data);
        $('.history__filters a').removeClass('active');
        btn.addClass('active');
    }).fail(function (data) {
        console.log('fail');
    });
}
$('.js-history-filter').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('active')) {
        getPosts($(this));
    }
});
