"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CONTROLLERS_DIRECTORY = "controllers";
var subscribers = [];
function getCurrentController() {
    return document.body.getAttribute("data-js-controller");
}
exports.default = {
    on: function (controller, path) {
        subscribers[controller] = [CONTROLLERS_DIRECTORY, path].join("/");
    },
    run: function () {
        var currentController = getCurrentController();
        if (currentController && subscribers[currentController]) {
            var module_1 = require("../" + subscribers[currentController]).default;
            if (module_1 && module_1.hasOwnProperty('onStart') && typeof module_1.onStart === 'function') {
                module_1.onStart();
            }
        }
    }
};
