"use strict";
/**
 * Helper methods.
 * Usage:
 * import {throttle} from "helpers/utils";
 * import {throttle, debounce} from "helpers/utils";
 *
 * Unused methods will be dropped during build process.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.merge = exports.find = exports.getQueryParam = exports.debounce = exports.throttle = void 0;
var throttle = function (fn, _threshold, scope) {
    var threshold = _threshold || 250;
    var last, deferTimer;
    return function () {
        var context = scope || this;
        var now = +new Date(), args = arguments;
        if (last && now < last + threshold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshold);
        }
        else {
            last = now;
            fn.apply(context, args);
        }
    };
};
exports.throttle = throttle;
var debounce = function (func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate)
                func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
    };
};
exports.debounce = debounce;
/**
 * Get query param from URL by key
 *
 * @param key
 * @returns string
 */
var getQueryParam = function (key) {
    var result = null, tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === key)
            result = decodeURIComponent(tmp[1]);
    });
    return result;
};
exports.getQueryParam = getQueryParam;
/**
 * Vanilla implementation of lodash's _.find( array, function(){})
 *
 * Usage:
 * find([{foo: "bar", boo: "far"}], function(n) {
 *  return n.foo === "bar";
 * });
 *
 * @param array
 * @param predicate
 * @returns {}
 */
function find(array, predicate) {
    if (array == null) {
        throw new TypeError('"array" is null or not defined');
    }
    var len = array.length >>> 0;
    if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
    }
    var thisArg = arguments[1];
    var k = 0;
    while (k < len) {
        var kValue = array[k];
        if (predicate.call(thisArg, kValue, k, array)) {
            return kValue;
        }
        k++;
    }
    return undefined;
}
exports.find = find;
/**
 * Merge objects
 *
 * @param obj
 * @param additional
 * @returns {Object}
 */
function merge(obj, additional) {
    var source, prop;
    for (var i = 1, length_1 = arguments.length; i < length_1; i++) {
        source = arguments[i];
        for (prop in source) {
            obj[prop] = source[prop];
        }
    }
    return obj;
}
exports.merge = merge;
