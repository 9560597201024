"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
global.Promise = require("es6-promise");
var router_1 = require("./modules/router");
var $ = require("jquery");
require("slick-carousel");
var Store = require("./helpers/store");
var AOS = require("../../../node_modules/aos/dist/aos");
var util_1 = require("util");
router_1.default.on("AboutPageController@index", "about");
router_1.default.on("LanguagePageController@index", "landing");
router_1.default.on("ProjectsPageController@index", "projects");
router_1.default.on("ProjectsPageController@project", "projects");
router_1.default.on("HistoryPageController@index", 'history');
router_1.default.run();
menu();
function menu() {
    var $btn = $('.burger');
    var $head = $('.header');
    $btn.on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.parent().toggleClass('open');
        $('body').toggleClass('locked');
        $head.slideToggle().toggleClass('open');
    });
}
function initSlider() {
    var $slider = $('.js-hero');
    if (!(0, util_1.isNullOrUndefined)($slider)) {
        $slider.slick({
            slidesToShow: 1,
            dots: false,
            arrows: false,
            fade: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 3000
        });
    }
}
initSlider();
var StylesLoaded = function () {
    return new Promise(function (resolve, reject) {
        var interval = setInterval(function () {
            if (Store.default.getCookie('stylesLoaded')) {
                clearInterval(interval);
                return resolve();
            }
        }, 10);
    });
};
var initAnimations = function () {
    AOS.init({
        duration: 500,
        once: true
    });
};
var openCurtain = function () {
    setTimeout(function () {
        $('.curtain').addClass('open');
    }, 1000);
};
StylesLoaded()
    .then(openCurtain)
    .then(initAnimations);
